import React, {useState, useEffect, useLayoutEffect, useMemo} from "react";
import { useNavigate } from "react-router-dom";

//Import styling
import { Display, ImagePosition, FinalImagePosition } from "../components/Styling";

//Import components
import MissingWord from "../gamedev/MissingWord";
import Layout from "../components/Layout";

//Import images
import balde from "../assets/Game2/balde.png";
import bandeira from "../assets/Game2/bandeira.png";
import barbatanas from "../assets/Game2/barbatanas.jpg";
import bigode from "../assets/Game2/bigode.png";
import borboleta from "../assets/Game2/borboleta.jpg";
import caderno from "../assets/Game2/caderno.png";
import dedo from "../assets/Game2/dedo.jpg";
import dominó from "../assets/Game2/domino.jpg";
import elefante from "../assets/Game2/elefante.png";
import gravata from "../assets/Game2/gravata.png";
import serpente from "../assets/Game2/serpente.jpg";
import tambor from "../assets/Game2/tambor.png";
import tapete from "../assets/Game2/tapete.png";
import teia from "../assets/Game2/teia.png";
import vestido from "../assets/Game2/vestido.jpg";
import smile from "../assets/GeralImagens/smile.png";

//import audios
import baldeAudio from '../assets/Game2/audio2/balde.mp3';
import bandeiraAudio from '../assets/Game2/audio2/bandeira.mp3';
import barbatanasAudio from '../assets/Game2/audio2/barbatanas.mp3';
import bigodeAudio from '../assets/Game2/audio2/bigode.mp3';
import borboletaAudio from '../assets/Game2/audio2/borboleta.mp3';
import cadernoAudio from '../assets/Game2/audio2/caderno.mp3';
import dedoAudio from '../assets/Game2/audio2/dedo.mp3';
import dominoAudio from '../assets/Game2/audio2/domino.mp3';
import elefanteAudio from '../assets/Game2/audio2/elefante.mp3';
import gravataAudio from '../assets/Game2/audio2/gravata.mp3';
import serpenteAudio from '../assets/Game2/audio2/serpente.mp3';
import tamborAudio from '../assets/Game2/audio2/tambor.mp3';
import tapeteAudio from '../assets/Game2/audio2/tapete.mp3';
import teiaAudio from '../assets/Game2/audio2/teia.mp3';
import vestidoAudio from '../assets/Game2/audio2/vestido.mp3';
import LettersButton from "../components/LetterButton";
import finishAud from '../assets/GeralAudios/finish.mp3';

const HandleImage = ({flag}) =>{
  let audio;
  switch (flag) {
    case "balde":
      audio = new Audio(baldeAudio);
      return(
      <div>
      <img src={balde} alt = "balde" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "bandeira":
      audio = new Audio(bandeiraAudio);
      return(
      <div>
      <img src={bandeira} alt = "bandeira" style= {ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "barbatanas":
      audio = new Audio(barbatanasAudio);
      return(
      <div>
      <img src={barbatanas} alt= "barbatanas" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "bigode":
      audio = new Audio(bigodeAudio);
      return(
      <div>
      <img src={bigode} alt="bigode" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "borboleta":
      audio = new Audio(borboletaAudio);
      return(
      <div>
      <img src={borboleta} alt="borboleta" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "caderno":
      audio = new Audio(cadernoAudio);
      return(
      <div>
      <img src={caderno} alt="caderno" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "dedo":
      audio = new Audio(dedoAudio);
      return(
      <div>
      <img src={dedo} alt="dedo" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "dominó":
      audio = new Audio(dominoAudio);
      return(
      <div>
      <img src={dominó} alt="dominó" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "elefante":
      audio = new Audio(elefanteAudio);
      return(
      <div>
      <img src={elefante} alt="elefante" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "gravata":
      audio = new Audio(gravataAudio);
      return(
      <div>
      <img src={gravata} alt="gravata" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "serpente":
      audio = new Audio(serpenteAudio);
      return(
      <div>
      <img src={serpente} alt="serpente" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>) 
    case "tambor":
      audio = new Audio(tamborAudio);
      return(
      <div>
      <img src={tambor} alt="tambor" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "tapete":
      audio = new Audio(tapeteAudio);
      return(
      <div>
      <img src={tapete} alt="tapete" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "teia":
      audio = new Audio(teiaAudio);
      return(
      <div>
      <img src={teia} alt="teia" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>)
    case "vestido":
      audio = new Audio(vestidoAudio);
      return(
      <div>
      <img src={vestido} alt="vestido" style={ImagePosition} onClick = {() => {audio.play()}}/>
      </div>) 
    default:
      return(
        <div>
        <img src={smile} alt="smile" style={FinalImagePosition} />
        </div>)
  }
  }

function chooseCorrectAnswer(challengeFlag, setCorrectAnswer){
  switch(challengeFlag){
    case 'balde':
        setCorrectAnswer('D');
    break;
    case 'bandeira':
        setCorrectAnswer('D');
    break;
    case 'bigode':
        setCorrectAnswer('D');
    break;
    case 'caderno':
        setCorrectAnswer('D');
    break;
    case 'dedo':
        setCorrectAnswer('D');
    break;
    case 'dominó':
        setCorrectAnswer('D');
    break;
    case 'barbatanas':
        setCorrectAnswer('T');
    break;
    case 'borboleta':
        setCorrectAnswer('T');
    break;
    case 'elefante':
        setCorrectAnswer('T');
    break;
    case 'gravata':
        setCorrectAnswer('T');
    break;
    case 'serpente':
        setCorrectAnswer('T');
    break;
    case 'tambor':
        setCorrectAnswer('T');
    break;
    case 'tapete':
        setCorrectAnswer('T');
    break;
    case 'teia':
        setCorrectAnswer('T');
    break;
    case 'vestido':
        setCorrectAnswer('T');
    break;
    default:
    break;
}
}

const Game2 = () => {
  const navigation = useNavigate();  
  const [challenges, setChallenges] = useState(['balde', 'bandeira', 'barbatanas', 'bigode', 'borboleta', 'caderno', 'dedo', 'dominó', 'elefante', 'gravata', 'serpente', 'tambor', 'tapete', 'teia', 'vestido']);
  const [challengeFlag, setChallengeFlag] = useState(challenges[Math.floor(Math.random() * challenges.length)]);  //initialize the challengeFlag randomly on first render
  const [fillWord, setFillWord] = useState(false);  //flag to complete the word displayed on the screen
  const [nextChallenge, setNextChallenge] = useState(false);  //flag to change challenge
  const [finishedGame, setFinishedGame] = useState(false);  //flag to the end of the game's condition
  const [correctAnswer, setCorrectAnswer] = useState(''); //flag to dictate which button gives the correct feedback
  const finishAudio = useMemo(() => new Audio(finishAud),[]);
  finishAudio.volume = 0.5;
    
  /* Updates correct answer everytime challengeFlag changes*/
  useEffect(() => {
      chooseCorrectAnswer(challengeFlag, setCorrectAnswer);
  },[challengeFlag]);
  
  /* When changing challenge phase, pop the challengeFlag from the challenges's array */
  useLayoutEffect(() =>{
    if(nextChallenge === true)  setChallenges(current => current.filter(element => {return element !== challengeFlag;}));
  },[nextChallenge, challengeFlag]);

  useEffect(()=>{
    //end of the game's condition
    if(challenges.length === 0 && !finishedGame) {
      finishAudio.play();
      setFinishedGame(true);
      setFillWord(true);
      setNextChallenge(false);
      setChallengeFlag('parabéns');
      //Returns the user to the homepage
      setTimeout(()=>{    
        navigation(
          "/",
          {
              replace: true
          }
        );
        }, 4500);
      return (()=>{clearTimeout()});
    }
  },[challenges,finishedGame, finishAudio]);
    
  //When changing challenge's phase it picks another challengeFlag randomly
  useEffect(()=>{
      if(nextChallenge && !finishedGame){
        setTimeout(() => { 
              setChallengeFlag(challenges[Math.floor(Math.random() * challenges.length)]);
              setNextChallenge(false);
          }, 1000);
          return () => clearTimeout();
      }
  },[nextChallenge, challengeFlag, challenges, finishedGame]);

  return  (
  <>
      <Layout title={'"D" ou "T"'} description={'Completa a palavra'} flag = {'2'}/>
      <Display>
              <HandleImage flag = {challengeFlag}/>
              <LettersButton leftletter={'D'} rightletter={'T'} finish = {finishedGame} correctAnswer = {correctAnswer} setFillWord = {setFillWord}/>
              <MissingWord finish = {finishedGame} correctAnswer = {correctAnswer} flag = {challengeFlag} fillWord = {fillWord} setFillWord = {setFillWord} setNextChallenge= {setNextChallenge}/>
      </Display>
  </>
  )  
}
export default Game2;