import React from "react";
import { useEffect, useState } from 'react';

//Import styling
import { Display } from "../components/Styling";

//Import components
import handleAudio from "../components/handleAudio";
import Image from "../gamedev/DevGame3";
import Layout from "../components/Layout";

//Import audios
import jogo3c from "../assets/Game3/audio3/jogo3c.mp3";
import jogo3g from "../assets/Game3/audio3/jogo3g.mp3";
 
const Game3 = () => {
    const [rightAnswer, setrightAnswer] = useState('C');
    const [i, setI] = useState(0);

    if(rightAnswer==='C' && i===1){
        handleAudio(jogo3c);
    }else if(rightAnswer==='G' && i===1){
        handleAudio(jogo3g);
    }
    useEffect(() => {
        updateAnswer();
    }, []);

    const updateAnswer = () => {
        setrightAnswer(rightAnswer);
    }
    
    return  (<>
        <Layout title={'"C" ou "G"'} description={`Seleciona as ilustrações que contêm a letra ${rightAnswer}`} flag = {'3'}/>
        <Display>
        <Image
            rightAnswer={rightAnswer}
            setrightAnswer={setrightAnswer}
            setI={setI}
        />
        </Display>
    </>
    )  
}

export default Game3;