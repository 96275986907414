import './App.css';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

import HomePage from './pages/Menus/HomePage';
import Game1 from './pages/Game1';
import Game2 from './pages/Game2';
import Game3 from './pages/Game3';
import Game4 from './pages/Game4';
import Game5 from './pages/Game5';
import Game6 from './pages/Game6';


const App = () => {

    return (
      <Router router>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/jogo1" element={<Game1 />} />
            <Route path="/jogo2" element={<Game2 />} />
            <Route path="/jogo3" element={<Game3 />} />
            <Route path="/jogo4" element={<Game4 />} />
            <Route path="/jogo5" element={<Game5 />} />
            <Route path="/jogo6" element={<Game6 />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
      </Router>
    ); 
}

export default App;
