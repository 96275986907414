import styled, {css} from 'styled-components';
import BackgroundOverlay from '../assets/HomePage/BackgroundOverlay.png';
import Map5 from "../assets/Game5/background.jpg";
import Map6 from "../assets/Game6/background.jpg";
import cestaF from "../assets/Game4/cestof.png";
import cestaV from "../assets/Game4/cestov.png";

//Home Page background image (doesn't include the logo)
export const HomePageBackground = {
    backgroundImage: `url(${BackgroundOverlay})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position:'relative',
    width: '100vw',
    height: '100vh',
};

//Home Page games' button styling
export const GameButton = styled.div`
    position: relative;
    background-color: #799FD8;
    color: #FFFFFF;
    border-radius: 100px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-family: 'Comic Neue';
    font-size: 35px;
    font-weight: bold;
    line-height: 80px;
    cursor: pointer;
    transition: ease background-color 250ms;
    &:hover {
        background-color:  #346dd7d7;
    }
`;

//Home Page info (informações) button styling
export const InfoButton = styled.div`
    position: relative;
    background-color: #a1bef8;
    color: #FFFFFF;
    border-radius: 100px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Comic Neue';
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 80px;
    cursor: pointer;
    transition: ease background-color 250ms;
`;

//Info box/pop-up styling
export const InfoBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

//Info button location on the screen
export const InfoButtonLocation = {
    position: 'absolute',
    width: '10%',
    height: '8%',
    bottom: '85%',
    left: '80%',
    zIndex: 100,
}

//Side bar left section
export const LeftSection = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 10vw;
    height: 100vh;
    background-color: #789FD8;
`;

//Side bar place to display buttons
export const ButtonBox = styled.div`
    position: relative;
    top: 10%;
    width: 80%;
    margin:10%;
    height: 60%;
`;

//Side bar menu button
export const MenuButton = styled.div`
    position: relative;
    top: 10%;
    width: 80%;
    margin:10%;
    height: 60%;
    background-color: #5cd6d6;
    color: #FFFFFF; //letter's color
    width: 80%;
    height: 8%;
    border-radius: 100px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Comic Neue';
    display: inline-block;
    font-size: 35px;
    font-weight: bold;
    line-height: 60px; 
    margin: 16% 10%;
    cursor: pointer;
    transition: ease background-color 300ms;
    &:hover {
        background-color: #9ee3efad;
    }
`;

//Side bar game button
export const GameButtonSB = styled.span`
  ${(props) => {
    switch (props.$mode) {
      case 'active':
        return css`
          position: relative;
          background-color: #ec457f;
          color: #ffffff; //letter's color
          width: 80%;
          height: 10%;
          border-radius: 100px;
          text-transform: uppercase;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          vertical-align: middle;
          font-size: 25px;
          font-family: 'Comic Neue';
          line-height: 50px; 
          margin: 14% 10%;
          cursor: pointer;
          transition: ease background-color 300ms;
          &:hover {
              background-color:  #bd0f4cd5;
          }`; 
      default:
        return css`
          position: relative;
          background-color: #A8EBF3;
          color: #789FD8; //letter's color
          width: 80%;
          height: 10%;
          border-radius: 100px;
          text-transform: uppercase;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          vertical-align: middle;
          font-size: 25px;
          font-family: 'Comic Neue';
          line-height: 50px; 
          margin: 14% 10%;
          cursor: pointer;
          transition: ease background-color 250ms;
          &:hover {
              background-color: #9ee3efad;
          }`;
    }
  }}
`;

//Display Game 1, Game 2, Game 3 and Game 4
export const Display = styled.div`
    position: absolute;
    aspect-ratio: 16/8;
    left: 20%;
    top: 20%;
    width: 70%;
    background-color: #ffffff;
`;

//Display Game 5, includes background image
export const Display5 = styled.div`
    position: absolute;
    aspect-ratio: 16/8;
    left: 20%;
    top: 20%;
    width: 70%;
    background-image: url(${Map5});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ffffff;
    border: 3px solid #ffffff;
    overflow: hidden;
`;

//Display Game 6, includes background image
export const Display6 = styled.div`
    position: absolute;
    aspect-ratio: 16/8;
    left: 20%;
    top: 20%;
    width: 70%;
    background-image: url(${Map6});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ffffff;
    border: 3px solid #ffffff;
    overflow: hidden;
`;

//Game description styling
export const Description = styled.div`
    color: #000000;
    text-transform: uppercase;
    text-decoration: none;
    display: inline;
    font-size: 25px;
    padding-top: 30;
    font-family: 'Comic Neue';
    text-align: center;
    position: absolute;
    left: 19%;
    width: 62%;
    top: 50%;
    height: 16%;
`;

//Game title styling
export const Title = styled.div`
    color: #000000;
    text-transform: uppercase;
    font-size: 50px;
    padding-top: 20;
    padding-left: 1;
    text-align: center;
    font-family: 'Comic Neue';
    position: absolute;
    left: 19%;
    width: 62%;
    height: 16%;
`;

//Game header which is the place that includes the title and description
export const Header = styled.div`
    position: absolute;
    left: 20%;
    width: 70%;
    top: 3%;
    height: 16%;
`;

//Game 1 and Game 2 image position and size
export const ImagePosition = {
    position: 'absolute',
    left: '40%',
    top: '30%' ,
    maxWidth : '250px',
    maxHeight: '250px',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
};

//Game 1 and Game 2: end of the game image style
export const FinalImagePosition = {
    position: 'absolute',
    left: '35%',
    top: '7%' ,
    maxWidth : '480px',
    maxHeight: '380px',
  }
 
//Game 1 and Game 2 word missing one letter styling
export const WordMissing = styled.div`
    font-size: 50px;
    padding-top: 0;
    padding-left: 1;
    text-align: center;
    font-family: 'Comic Neue';
    position: absolute;
    left: 19%;
    width: 62%;
    height: 40%;
    bottom: -20%;
    right: 10%;
`;

//Game 1 and Game 2 single button styling
export const SingleLetterButton = styled.button`
    ${(props) => {
        if(props.colorFlag)
            if(props.correctButton === props.side)
                return css`
                    position: relative;
                    background-color:#11a54a;
                    color: #000000;
                    border-radius: 100px;
                    text-align: center;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-family: 'Comic Neue';
                    display: inline-block;
                    font-size: 35px;
                    font-weight: bold;
                    line-height: 80px;
                    cursor: pointer;
                    border: 3px solid black;
                    transition: ease background-activateLeft 250ms;
                `;
            else
                return css`
                    position: relative;
                    background-color: #ec1616;
                    color: #000000;
                    border-radius: 100px;
                    text-align: center;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-family: 'Comic Neue';
                    display: inline-block;
                    font-size: 35px;
                    font-weight: bold;
                    line-height: 80px;
                    cursor: pointer;
                    border: 3px solid black;
                    transition: ease background-activateLeft 250ms;
                `;
        else
            return css`
                position: relative;
                background-color:#ffffff;
                color: #000000;
                border-radius: 100px;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;
                font-family: 'Comic Neue';
                display: inline-block;
                font-size: 35px;
                font-weight: bold;
                line-height: 80px;
                cursor: pointer;
                border: 3px solid black;
                transition: ease background-activateLeft 250ms;
            `;
        }
     }
`;

//Game 5 and Game 6: words inside pop-up styling
export const PopUpWord = styled.span`
${(props) => {
  if(props.active === true){
    switch (props.$mode) {
      case 'correct':
      return css`
      position:relative;
      background-color:#06b957;
      color: #ffffff;
      border-radius: 200px;
      text-transform: uppercase;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 20px;
      font-family: 'Comic Neue';
      line-height:40px;
      cursor:pointer;
      transition: ease background-color 250ms;
      `;
      default:
      return css`
      position: relative;
      background-color: #f20808;
      color: #ffffff;
      border-radius: 200px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      font-family: 'Comic Neue';
      display: inline-block;
      font-size: 20px;
      line-height: 40px;
      cursor: pointer;
      transition: ease background-color 250ms;
      `;
    }}
  else
    return css`
    position: relative;
    background-color: #799FD8;
    color: #ffffff;
    border-width:thick;
    border-radius: 200px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-family: 'Comic Neue';
    display: inline-block;
    font-size: 20px;
    line-height: 40px;
    cursor: pointer;
    transition: ease background-color 250ms;
    `;
}}`;

//Game 5 and Game 6: Story telling play button
export const PlayButton = styled.button`
  background-color: #328e43;
  color: white;
  border-radius: 50%;
  padding: 20px 30px;
  width: 70px;
  height: 50px;
  border: none;
  cursor: pointer;
  position: relative;

  &:before {
    content: "";
    border-style: solid;
    border-width: 8px 0 8px 12px;
    border-color: transparent transparent transparent white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(125deg);
  }
`;

//Game 4: baskets display
export const Cestas = styled.div`
    ${(props) => {
        switch (props.type) {
            case "F":
                return css`
                    position: absolute;
                    background-size: cover;
                    background-image: url(${cestaF});`
            case "V":
            default:
                return css`
                    position: absolute;
                    background-size: cover;
                    background-image: url(${cestaV});`
        }
    }
}
`;
