import React, { useState, useEffect } from 'react';

//Import styling
import { SingleLetterButton } from './Styling';

//Import audios
import correctAud from '../assets/GeralAudios/correct.wav';
import wrongAud from '../assets/GeralAudios/wrong.mp3';

//Position for the two buttons
const styles = {
    positionL:{
        position: 'absolute',
        width: '100px',
        height: '80px',
        bottom: '75%',
        left: '30%',
    },
    positionR:{
        position: 'absolute',
        width: '100px',
        height: '80px',
        bottom: '75%',
        left: '60%',
    },
};

// LettersButton component
const LettersButton = (props) => {
    // Initialize correct and wrong sound effects
    const correctAudio = new Audio(correctAud);
    correctAudio.volume = 0.5;
    const wrongAudio = new Audio(wrongAud);
    wrongAudio.volume = 0.5;

    const [activateLeft, setActivateLeft] = useState(false);    // 'D/M' button
    const [activateRight, setActivateRight] = useState(false);  // 'T/N' button
    const [correctButton, setCorrectButton] = useState('');

// The useEffect hook is used to update the state "correctButton" based on the value of "props.correctAnswer"
useEffect(() => {
    // A switch statement to determine which button is the correct one based on the value of "props.correctAnswer"
    switch(props.correctAnswer){
        case props.leftletter:
            // If "props.correctAnswer" is 'D/M', set the state "correctButton" to 'left'
            setCorrectButton('left');
        break;
        case props.rightletter:
            // If "props.correctAnswer" is 'T/N', set the state "correctButton" to 'right'
            setCorrectButton('right');
        break;
        default:
        break;
    }
    // The second argument of the useEffect hook is an array of dependencies, which tells the hook to only re-run the effect if the values in the array change. Here, we depend on "props.correctAnswer", so the effect will re-run only if the value of "props.correctAnswer" changes.
},[props.correctAnswer]);

// The handleTransition function is called when the correct button is clicked, it sets the "fillWord" state in "props" to true and sets a timeout to de-activate both buttons after 1 second.
    /* Function called when the user answers correctly, the word is completed and we enter in a new phase of the game */
function handleTransition() { 
    props.setFillWord(true);
    setTimeout(() => { 
        setActivateLeft(false);
        setActivateRight(false);
  }, 1000);
 // Return a function to clear the timeout when the component unmounts.
 return () => { clearTimeout();}
};

// The handleLeftButton function is called when the left button is clicked
function handleLeftButton(){
    // Toggles the state "activateLeft"
    setActivateLeft(!activateLeft);
    // If the "correctButton" state is 'left', play the "correctAudio" and call the "handleTransition" function
    if(correctButton === 'left'){
        correctAudio.play();
        handleTransition();
    }    
    // If the "correctButton" state is not 'left', play the "wrongAudio"
    else    
        wrongAudio.play();
}

// The handleRightButton function is called when the right button is clicked
function handleRightButton(){
    // Toggles the state "activateRight"
    setActivateRight(!activateRight);
    // If the "correctButton" state is 'right', play the "correctAudio" and call the "handleTransition" function
    if(correctButton === 'right'){
        correctAudio.play();
        handleTransition();
    }    
    // If the "correctButton" state is not 'right', play the "wrongAudio"
    else    
        wrongAudio.play();
}

    // Return the component
    return(
        <div>
            {/* 'D' button */}
            <SingleLetterButton side = 'left' correctButton = {correctButton} colorFlag = {activateLeft}  onClick={() => {handleLeftButton()}} style={props.finish ? {display:'none'} : styles.positionL}>{props.leftletter}</SingleLetterButton>
            {/* 'T' button */}
            <SingleLetterButton side = 'right' correctButton = {correctButton} colorFlag = {activateRight} onClick={() => {handleRightButton()}} style={props.finish ? {display:'none'} : styles.positionR}>{props.rightletter}</SingleLetterButton>
        </div>
    )
}
export default LettersButton;