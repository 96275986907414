import React, {useLayoutEffect, useRef, useState, useEffect} from 'react';
import useEventListener from '@use-it/event-listener';
import { useNavigate } from "react-router-dom";

//Import styling
import { Display6, PlayButton } from '../components/Styling';

//Import components
import Popup6 from "../gamedev/Popup6";
import Layout from "../components/Layout";
import Movement from "../gamedev/Movement";

//Import images
import olga from "../assets/Game6/olga.png"

//Import audios
import finish from "../assets/GeralAudios/finish.mp3";
import audio1 from "../assets/Game6/audio6/audio1.mp3";
import audio2 from "../assets/Game6/audio6/audio2.mp3";
import audio3 from "../assets/Game6/audio6/audio3.mp3";
import audio4 from "../assets/Game6/audio6/audio4.mp3";
import audio5 from "../assets/Game6/audio6/audio5.mp3";
import audio6 from "../assets/Game6/audio6/audio6.mp3";
import audio7 from "../assets/Game6/audio6/audio7.mp3";
import audio8 from "../assets/Game6/audio6/audio8.mp3";
import audio9 from "../assets/Game6/audio6/audio9.mp3";
import audio10 from "../assets/Game6/audio6/audio10.mp3";
import audio11 from "../assets/Game6/audio6/audio11.mp3";

function handlePhaseAudio(phaseNum){
    switch (phaseNum) {
      case 0:
        return new Audio(audio1);
      case 1:
        return new Audio(audio2);
      case 2:
        return new Audio(audio3);
      case 3:
        return new Audio(audio4);
      case 4:
        return new Audio(audio5);
      case 5:
        return new Audio(audio6);
      case 6:
        return new Audio(audio7);    
      case 7:
        return new Audio(audio8);      
      case 8:
        return new Audio(audio9);    
      case 9:
        return new Audio(audio10);      
      case 10:
      default:
        return new Audio(audio11);   
    }
}

function playAudio(phaseAudio){
  setTimeout(()=>{
    phaseAudio.play();
  },1500);
};

const finishGame = ({navigation}) => {
  const finishAudio = new Audio(finish); 
  setTimeout(() => {
    finishAudio.play();
  }, 1000);
  setTimeout(()=>{
    navigation(
      "/",
      {
          replace: true
      }
  );
  },6000);
};

const Game6 = () => {
  const navigation = useNavigate();
  const objects = ['alface','alguidar','alga','anel','almofada','alfinete','balde','colmeia','funil','caracol','aldeia']; 
  const canvas = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [evokeP, setEvokeP] = useState(false);  //Flag to open the modal window
  const [modalFlag, setmodalFlag] = useState(''); //Flag to identify an object's corresponding modal window
  const [phaseNum, setPhaseNum] = useState(0);  
  const [phaseAudio, setPhaseAudio] = useState(handlePhaseAudio(phaseNum));
  
  /* Depending on the phase's number, it evokes the end of the game's condition or it simply changes the audio for the next phase */
  useEffect(() => {
    if(phaseNum === 11) finishGame({navigation});
    else setPhaseAudio(handlePhaseAudio(phaseNum));
    }, [phaseNum]);
  
  useEffect(() => {
    let timeoutId;

    if (phaseNum === 0) {
      timeoutId = setTimeout(() => {
        playAudio(phaseAudio);
      }, 6000);
    } else {
      playAudio(phaseAudio);
    }
    return () => clearTimeout(timeoutId);
  }, [phaseAudio]);

  /* Gets the proportions of the page on the user's display on first render */
  useLayoutEffect(() => {
      setWidth(canvas.current.offsetWidth);
      setHeight(canvas.current.offsetHeight);
    }, []);

  /* Updates the values of width/height based on the page on the user's display everytime the page is resized */
  function limitHandler(){
      setWidth(canvas.current.offsetWidth);
      setHeight(canvas.current.offsetHeight);
  }
  useEventListener('resize', limitHandler);
  return ( <>
          <Layout title={'"AL/EL"'} description={'Desloca a Olga com as setas'} flag = {'6'}/>
          <Display6 ref = {canvas}> 
              <Movement game={6} src = {olga} width = {width} height = {height} objects = {objects} setFlag = {setEvokeP} Flag = {evokeP} setModalFlag = {setmodalFlag} modalFlag = {modalFlag} phaseNum = {phaseNum}/>
              <Popup6 width = {width} height = {height} setOpenFlag = {setEvokeP} openFlag = {evokeP} modalFlag = {modalFlag} setPhaseNum = {setPhaseNum} phaseAudio = {phaseAudio}/>
          </Display6> 
        <PlayButton style = {{position: 'absolute', left : "53.5%", top : "15%"}} onClick = {() => {phaseAudio.play()}}/>

  </>)
}
export default Game6;