import React, {useEffect, useState, useMemo} from 'react';

//Import components
import useDrag from "../components/useDrag";

//Import audios
import correctAud from '../assets/GeralAudios/correct.wav';
import wrongAud from '../assets/GeralAudios/wrong.mp3';
import afiaAud from '../assets/Game4/audio4/afia.mp3';
import alfaceAud from '../assets/Game4/audio4/alface.mp3';
import almofadaAud from '../assets/Game4/audio4/almofada.mp3';
import aventalAud from '../assets/Game4/audio4/avental.mp3';
import avionetaAud from '../assets/Game4/audio4/avioneta.mp3';
import cafeteiraAud from '../assets/Game4/audio4/cafeteira.mp3';
import chaveAud from '../assets/Game4/audio4/chave.mp3';
import chavenaAud from '../assets/Game4/audio4/chavena.mp3';
import chuveiroAud from '../assets/Game4/audio4/chuveiro.mp3';
import escovaAud from '../assets/Game4/audio4/escova.mp3';
import formigaAud from '../assets/Game4/audio4/formiga.mp3';
import funilAud from '../assets/Game4/audio4/funil.mp3';
import garfoAud from '../assets/Game4/audio4/garfo.mp3';
import gravataAud from '../assets/Game4/audio4/gravata.mp3';
import parafusoAud from '../assets/Game4/audio4/parafuso.mp3';
import sofaAud from '../assets/Game4/audio4/sofa.mp3';
import vacaAud from '../assets/Game4/audio4/vaca.mp3';
import velaAud from '../assets/Game4/audio4/vela.mp3';
import ventoinhaAud from '../assets/Game4/audio4/ventoinha.mp3';

function findImgSource(array1, array2, source){
    /* Compares the image's source string to the objects in both arrays*/
    const combinedArray = array1.concat(array2);
    const imgSource = combinedArray.find((word) => source.includes(word));
    if(source.includes("chavena"))    return "chavena";
    return imgSource;
}

function defineObjectAudio(imageSource){
    switch (imageSource) {
        case "afia":
            return new Audio(afiaAud);
        case "alface":
            return new Audio(alfaceAud);   
        case "almofada":
            return new Audio(almofadaAud);
        case "avental":
            return new Audio(aventalAud);
        case "avioneta":
            return new Audio(avionetaAud);
        case "cafeteira":
            return new Audio(cafeteiraAud);   
        case "chave":
            return new Audio(chaveAud);
        case "chavena":
            return new Audio(chavenaAud);
        case "chuveiro":
            return new Audio(chuveiroAud);
        case "escova":
            return new Audio(escovaAud);   
        case "formiga":
            return new Audio(formigaAud);
        case "funil":
            return new Audio(funilAud);  
        case "garfo":
            return new Audio(garfoAud);
        case "gravata":
            return new Audio(gravataAud); 
        case "parafuso":
            return new Audio(parafusoAud);
        case "sofa":
            return new Audio(sofaAud); 
        case "vaca":
            return new Audio(vacaAud);
        case "vela":
            return new Audio(velaAud);
        case "ventoinha":
            return new Audio(ventoinhaAud);
        default:
            break;
    }
}

const MovingImage = (props) => {
    const correctAudio = useMemo(() => new Audio(correctAud), []);
    const wrongAudio = useMemo(() => new Audio(wrongAud), []);
    correctAudio.volume = 0.5;
    wrongAudio.volume = 0.5;

    const [objectAudio, setObjectAudio] = useState('');
    const [isVisible, setIsVisible] = useState(true);   //Object's image flag to become invisible or not
    const [type, setType] = useState('');
    
    const objectsF = useMemo(()=>["afia","alface","almofada","cafeteira","formiga","funil","garfo","parafuso","sofa"],[]);
    const objectsV = useMemo(()=>["avental","avioneta","chave","chavena","chuveiro","escova","gravata","vaca","vela","ventoinha"],[]);
    
    const [image, setImage] = useState(props.images[props.index]);

    /* Change image */
    useEffect(()=>{
        setImage(props.images[props.index]);
    },[props.images, props.index]);

    /* Change the coordinates of the image by drag and dropping it */
    const [coords, ref] = useDrag(image.coords, isVisible);
    /* Returns the name of object that the image represents */
    const imageSource = findImgSource(objectsF, objectsV, image.src);
    
    /* Defines the audio onClick based on the object that the image represents */
    useEffect(()=>{
        setObjectAudio(defineObjectAudio(imageSource));
    },[imageSource])
    
    /* Classifies the image based on the object it represents */
    useEffect(() => {
        if(objectsF.some(word => image.src.includes(word))) setType('F');
        if(objectsV.some(word => image.src.includes(word))) setType('V'); 
    }, [image, objectsF, objectsV]);

    /* Checks if the images is being dropped on the correct basket or not, giving feedback */
    useEffect(() => {
      if(coords.x >= props.width * 0.05 && coords.x <= props.width * 0.23 && coords.y >= props.height * 0.5 && coords.y <= props.height * 0.8){
        if(type === 'F'){
            if(isVisible){
                props.setCount((prevCount) => prevCount + 1);
                correctAudio.play();
            }
            setIsVisible(false);
        }    
        if(type === 'V'){
            if(isVisible)   wrongAudio.play();
        }
      }
      if(coords.x >= props.width * 0.653 && coords.x <= props.width * 0.85 && coords.y >= props.height * 0.5 && coords.y <= props.height * 0.8){
        if(type === 'V'){
            if(isVisible){
                props.setCount((prevCount) => prevCount + 1);
                correctAudio.play();
            }   
            setIsVisible(false);
        }    
        if(type === 'F'){
            if(isVisible)   wrongAudio.play();
        }    
      }
    }, [coords, props, type, correctAudio, wrongAudio, isVisible]);
    
    useEffect(() => {
      if(props.nextPhase)   setIsVisible(true);
    }, [props.nextPhase]);

    return (
        <div>
            <img
                alt=""
                key={props.index}
                ref={ref}
                src={image.src}
                style={{
                display: isVisible ? 'flex' : 'none',
                position: 'absolute',
                maxHeight: props.width*0.12,
                maxWidth: props.width*0.12,
                left: coords.x,
                top: coords.y,
                cursor: 'grab',
                }}
                onClick = {e => {if(e.detail === 2) objectAudio.play()}} 
            />
        </div>
    );
};
export default MovingImage;