import React, {useEffect, useState} from 'react';
import useEventListener from '@use-it/event-listener'

/* Locates the different objects on game 5's map and checks if the character has reached an object. The flag to open the object's modal is only set to true if the character reaches the object in the correct sequence of the story*/
function objectCoordinatesGame5(modalFlag, objects, topValue, leftValue, height, width, setFlag, setModalFlag, phaseNum){
    if(!(modalFlag === objects[0]) && (parseInt(topValue) >= (height*0.66)) && (parseInt(leftValue) >= (width*0.74) && parseInt(leftValue) <= (width*0.81)) && phaseNum === 6)
    {
        setFlag(true);
        setModalFlag(objects[0]);   //Esqueleto
    }
    else if(!(modalFlag === objects[1]) &&(parseInt(topValue) >= (height*0.56) && parseInt(topValue) <= (height*0.63)) && (parseInt(leftValue) >= (width*0.47) && parseInt(leftValue) <= (width*0.52)) && phaseNum === 5)
    {
        setFlag(true);
        setModalFlag(objects[1]); //Estatua
    }
    else if(!(modalFlag === objects[2]) &&(parseInt(topValue) >= (height*0.08) && parseInt(topValue) <= (height*0.15)) && (parseInt(leftValue) >= (width*0.15) && parseInt(leftValue) <= (width*0.2)) && phaseNum === 0)
    {
        setFlag(true);
        setModalFlag(objects[2]);   //Esquilo
    }
    else if(!(modalFlag === objects[3]) &&(parseInt(topValue) >= (height*0.25) && parseInt(topValue) <= (height*0.33)) && (parseInt(leftValue) >= (width*0.75) && parseInt(leftValue) <= (width*0.8)) && phaseNum === 3)
    {
        setFlag(true);
        setModalFlag(objects[3]);   //Espelho
    }
    else if(!(modalFlag === objects[4]) &&(parseInt(topValue) >= (height*0.58) && parseInt(topValue) <= (height*0.67)) && (parseInt(leftValue) >= (width*0.08) && parseInt(leftValue) <= (width*0.14)) && phaseNum === 1)
    {
        setFlag(true);
        setModalFlag(objects[4]);   //Escada
    }
    else if(!(modalFlag === objects[5])&& (parseInt(topValue) >= (height*0.27) && parseInt(topValue) <= (height*0.33)) && (parseInt(leftValue) >= (width*0.34) && parseInt(leftValue) <= (width*0.39)) && phaseNum === 2)
    {
        setFlag(true);
        setModalFlag(objects[5]);   //Espada
    }
    else if(!(modalFlag === objects[6]) &&(parseInt(topValue) <= (height*0.04)) && (parseInt(leftValue) >= (width*0.38) && parseInt(leftValue) <= (width*0.44)) && phaseNum === 4)
    {
        setFlag(true);
        setModalFlag(objects[6]);   //Estojo
    }
    else if(!(modalFlag === objects[7]) &&(parseInt(topValue) >= (height*0.53) && parseInt(topValue) <= (height*0.60)) && (parseInt(leftValue) >= (width*0.84) && parseInt(leftValue) <= (width*0.92)) && phaseNum === 7)
    {
        setFlag(true);
        setModalFlag(objects[7]);   //Escola
    }
}

/* Locates the different objects on game 6's map and checks if the character has reached an object. The flag to open the object's modal is only set to true if the character reaches the object in the correct sequence of the story*/
function objectCoordinatesGame6(modalFlag, objects, topValue, leftValue, height, width, setFlag, setModalFlag, phaseNum){
    if(!(modalFlag === objects[0]) && (parseInt(topValue) >= (height*0.04) && parseInt(topValue) <= (height*0.09)) && (parseInt(leftValue) >= (width*0.075) && parseInt(leftValue) <= (width*0.12)) && phaseNum === 0)
    {
        setFlag(true);
        setModalFlag(objects[0]);   //Alface
    }
    else if(!(modalFlag === objects[1]) && (parseInt(topValue) >= (height*0.66)) && (parseInt(leftValue) >= (width*0.037) && parseInt(leftValue) <= (width*0.074)) && phaseNum === 2)
    {
        setFlag(true);
        setModalFlag(objects[1]);   //Alguidar
    }
    else if(!(modalFlag === objects[2]) &&(parseInt(topValue) >= (height*0.72)) && (parseInt(leftValue) >= (width*0.34) && parseInt(leftValue) <= (width*0.4)) && phaseNum === 3)
    {
        setFlag(true);
        setModalFlag(objects[2]);   //Alga   
    }
    else if(!(modalFlag === objects[3]) &&(parseInt(topValue) >= (height*0.57) && parseInt(topValue) <= (height*0.65)) && (parseInt(leftValue) >= (width*0.68) && parseInt(leftValue) <= (width*0.71)) && phaseNum === 5)
    {
        setFlag(true);
        setModalFlag(objects[3]);   //Anel 
    }
    else if(!(modalFlag === objects[4]) &&(parseInt(topValue) >= (height*0.02) && parseInt(topValue) <= (height*0.15)) && (parseInt(leftValue) >= (width*0.4) && parseInt(leftValue) <= (width*0.47)) && phaseNum === 7)
    {
        setFlag(true);
        setModalFlag(objects[4]);   //Almofada 
    }
    else if(!(modalFlag === objects[5])&& (parseInt(topValue) >= (height*0.04) && parseInt(topValue) <= (height*0.11)) && (parseInt(leftValue) >= (width*0.69) && parseInt(leftValue) <= (width*0.74)) && phaseNum === 9)
    {
        setFlag(true);
        setModalFlag(objects[5]);   //Alfinete 
    }
    else if(!(modalFlag === objects[6]) &&(parseInt(topValue) >= (height*0.4) && parseInt(topValue) <= (height*0.52)) && (parseInt(leftValue) >= (width*0.22) && parseInt(leftValue) <= (width*0.27)) && phaseNum === 1)
    {
        setFlag(true);
        setModalFlag(objects[6]);   //Balde 
    }
    else if(!(modalFlag === objects[7]) &&(parseInt(topValue) >= (height*0.33) && parseInt(topValue) <= (height*0.5)) && (parseInt(leftValue) >= (width*0.43) && parseInt(leftValue) <= (width*0.48)) && phaseNum === 4)
    {
        setFlag(true);
        setModalFlag(objects[7]);   //Colmeia
    }
    else if(!(modalFlag === objects[8]) &&(parseInt(topValue) <= (height*0.037)) && (parseInt(leftValue) >= (width*0.86)) && phaseNum === 8)
    {
        setFlag(true);
        setModalFlag(objects[8]);   //Funil
    }
    else if(!(modalFlag === objects[9]) &&(parseInt(topValue) >= (height*0.68)) && (parseInt(leftValue) >= (width*0.75) && parseInt(leftValue) <= (width*0.82)) && phaseNum === 6)
    {
        setFlag(true);
        setModalFlag(objects[9]);   //Caracol
    }
    else if(!(modalFlag === objects[10]) &&(parseInt(topValue) >= (height*0.28) && parseInt(topValue) <= (height*0.44)) && (parseInt(leftValue) >= (width*0.65) && parseInt(leftValue) <= (width*0.77)) && phaseNum === 10)
    {
        setFlag(true);
        setModalFlag(objects[10]);  //Aldeia
    }
}

const Movement = (props) => {
    const[topValue, setTopValue] = useState(0); //y coordinate of the character
    const[leftValue, setLeftValue] = useState(0);   //x coordinate of the character
    let dimensionValues = (props.game === 5) ? [0.25,0.45]:[0.22,0.75]; //[maxHeight parameter, aspectRatio] for indio and olga respectively
    
    /* Depending if we're playing game 5 or 6, the correct funtion for the objects on the map is called */
    useEffect(() => {
        if(props.width === 0 || props.height === 0) return;

        if(props.game === 5 )  objectCoordinatesGame5(props.modalFlag, props.objects, topValue, leftValue, props.height, props.width, props.setFlag, props.setModalFlag, props.phaseNum);
        else    objectCoordinatesGame6(props.modalFlag, props.objects, topValue, leftValue, props.height, props.width, props.setFlag, props.setModalFlag, props.phaseNum);

    },[props,topValue, leftValue]);

    /* Function responsible for the movement of the character using the arrowkeys on the keyboard */
    function coordHandler(key){
        let newValue = '';
        switch (String(key.code)) {
            case "ArrowLeft":
                newValue = parseInt(leftValue) - 10;
                if(newValue>=0) setLeftValue(newValue + 'px');  //Only updates coordinates while the character is still within the map's boundaries
                break;
            case 'ArrowRight':
                newValue = parseInt(leftValue) + 10;
                if(newValue<=(props.width-(props.height*dimensionValues[0])*dimensionValues[1]))  setLeftValue(newValue + 'px'); //Only updates coordinates while the character is still within the map's boundaries
                break;
            case 'ArrowUp':
                newValue = parseInt(topValue) - 10;
                if(newValue>=0)  setTopValue(newValue + 'px');  //Only updates coordinates while the character is still within the map's boundaries
                break;
            case 'ArrowDown':
                newValue = parseInt(topValue) + 10;
                if(newValue<=(props.height-props.height*dimensionValues[0]))  setTopValue(newValue + 'px'); //Only updates coordinates while the character is still within the map's boundaries
                break;
            default:
                break;
        }
    }
    useEventListener('keydown', coordHandler);

    return (<div>
        <img src = {props.src} alt = "" style= {{ position: 'relative', maxHeight: props.height*dimensionValues[0], aspectRatio: dimensionValues[1], top: topValue, left: leftValue}}/>
        </div>);
}
export default Movement;