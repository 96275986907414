import React, { useState, useEffect } from "react";

//Import images
import Logo from "../../assets/HomePage/logo.png";

//Import styling
import { HomePageBackground } from "../../components/Styling";

//Import audio
import oficinadossons from '../../assets/GeralAudios/oficinadossons.mp3';

//Import components
import HomePageButton from "./HomePageButtons";
import BasicModal from "./Info"; 

const HomePage = () => {
    const [audio, setAudio] = useState(null);

    useEffect(() => {
        setAudio(new Audio(oficinadossons));
    }, []);

    return (
    <div style={HomePageBackground}>
        <HomePageButton/>
        <img src= {Logo} alt="" style={{position: 'relative', left: '2%', top: '0.5%', height : '37.5%'}} onClick = {() => {audio.play()}}/>
        <BasicModal/>
    </div>
    )
}

export default HomePage;
