import React, {useState, useEffect, useLayoutEffect, useMemo} from "react";
import { useNavigate } from "react-router-dom";

//Import styling
import { Display, ImagePosition, FinalImagePosition } from "../components/Styling";

//Import components
import MissingWord from "../gamedev/MissingWord";
import Layout from "../components/Layout"

//Import images
import barbatanas from "../assets/Game1/barbatanas.jpg";
import caderno from "../assets/Game1/caderno.png";
import camelo from "../assets/Game1/camelo.jpg";
import caminho from "../assets/Game1/caminho.jpg";
import caneta from "../assets/Game1/caneta.jpg";
import canoa from "../assets/Game1/canoa.jpg";
import cogumelo from "../assets/Game1/cogumelo.jpg";
import macaco from "../assets/Game1/macaco.jpg";
import mala from "../assets/Game1/mala.jpg";
import médico from "../assets/Game1/medico.jpg";
import microfone from "../assets/Game1/microfone.png";
import moeda from "../assets/Game1/moeda.jpg";
import mota from "../assets/Game1/mota.jpg";
import nariz from "../assets/Game1/nariz.jpg";
import nove from "../assets/Game1/nove.jpg";
import novelo from "../assets/Game1/novelo.jpg";
import panela from "../assets/Game1/panela.jpg";
import pena from "../assets/Game1/pena.jpg";
import smile from "../assets/GeralImagens/smile.png";

//Import audios
import barbatanasAudio from '../assets/Game1/audio1/barbatanas.mp3';
import cadernoAudio from '../assets/Game1/audio1/caderno.mp3';
import cameloAudio from '../assets/Game1/audio1/camelo.mp3';
import caminhoAudio from '../assets/Game1/audio1/caminho.mp3';
import canetaAudio from '../assets/Game1/audio1/caneta.mp3';
import canoaAudio from '../assets/Game1/audio1/canoa.mp3';
import cogumeloAudio from '../assets/Game1/audio1/cogumelo.mp3';
import macacoAudio from '../assets/Game1/audio1/macaco.mp3';
import malaAudio from '../assets/Game1/audio1/mala.mp3';
import medicoAudio from '../assets/Game1/audio1/medico.mp3';
import microfoneAudio from '../assets/Game1/audio1/microfone.mp3';
import moedaAudio from '../assets/Game1/audio1/moeda.mp3';
import motaAudio from '../assets/Game1/audio1/mota.mp3';
import narizAudio from '../assets/Game1/audio1/nariz.mp3';
import noveAudio from '../assets/Game1/audio1/nove.mp3';
import noveloAudio from '../assets/Game1/audio1/novelo.mp3';
import panelaAudio from '../assets/Game1/audio1/panela.mp3';
import penaAudio from '../assets/Game1/audio1/pena.mp3';
import  LettersButton from "../components/LetterButton";
import finishAud from '../assets/GeralAudios/finish.mp3';

const HandleImage = ({flag}) =>{
    let audio;
    switch (flag) {
      case "barbatanas":
        audio = new Audio(barbatanasAudio);
        return(
        <div>
        <img src={barbatanas} alt = "barbatanas" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "caderno":
        audio = new Audio(cadernoAudio);
        return(
        <div>
        <img src={caderno} alt = "caderno" style= {ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "camelo":
        audio = new Audio(cameloAudio);
        return(
        <div>
        <img src={camelo} alt= "camelo" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "caminho":
        audio = new Audio(caminhoAudio);
        return(
        <div>
        <img src={caminho} alt="caminho" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "caneta":
        audio = new Audio(canetaAudio);
        return(
        <div>
        <img src={caneta} alt="caneta" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "canoa":
        audio = new Audio(canoaAudio);
        return(
        <div>
        <img src={canoa} alt="canoa" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "cogumelo":
        audio = new Audio(cogumeloAudio);
        return(
        <div>
        <img src={cogumelo} alt="cogumelo" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "macaco":
        audio = new Audio(macacoAudio);
        return(
        <div>
        <img src={macaco} alt="macaco" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "mala":
        audio = new Audio(malaAudio);
        return(
        <div>
        <img src={mala} alt="mala" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "médico":
        audio = new Audio(medicoAudio);
        return(
        <div>
        <img src={médico} alt="medico" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "microfone":
        audio = new Audio(microfoneAudio);
        return(
        <div>
        <img src={microfone} alt="microfone" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>) 
      case "moeda":
        audio = new Audio(moedaAudio);
        return(
        <div>
        <img src={moeda} alt="moeda" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "mota":
        audio = new Audio(motaAudio);
        return(
        <div>
        <img src={mota} alt="mota" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "nariz":
        audio = new Audio(narizAudio);
        return(
        <div>
        <img src={nariz} alt="nariz" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "nove":
        audio = new Audio(noveAudio);
        return(
        <div>
        <img src={nove} alt="nove" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "novelo":
        audio = new Audio(noveloAudio);
        return(
        <div>
        <img src={novelo} alt="novelo" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "panela":
        audio = new Audio(panelaAudio);
        return(
        <div>
        <img src={panela} alt="panela" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)
      case "pena":
        audio = new Audio(penaAudio);
        return(
        <div>
        <img src={pena} alt="pena" style={ImagePosition} onClick = {() => {audio.play()}}/>
        </div>)                
        default:
          return(
            <div>
            <img src={smile} alt="smile" style={FinalImagePosition} />
            </div>)
    }
  }

function chooseCorrectAnswer(challengeFlag, setCorrectAnswer){
  switch(challengeFlag){
    case 'barbatanas':
        setCorrectAnswer('N');
    break;
    case 'caderno':
        setCorrectAnswer('N');
    break;
    case 'camelo':
        setCorrectAnswer('M');
    break;
    case 'caminho':
        setCorrectAnswer('M');
    break;
    case 'caneta':
        setCorrectAnswer('N');
    break;
    case 'canoa':
        setCorrectAnswer('N');
    break;
    case 'cogumelo':
        setCorrectAnswer('M');
    break;
    case 'macaco':
      setCorrectAnswer('M');
    break;
    case 'mala':
      setCorrectAnswer('M');
    break;
    case 'médico':
      setCorrectAnswer('M');
    break;
    case 'microfone':
        setCorrectAnswer('M');
    break;
    case 'moeda':
        setCorrectAnswer('M');
    break;
    case 'mota':
        setCorrectAnswer('M');
    break;
    case 'nariz':
        setCorrectAnswer('N');
    break;
    case 'nove':
        setCorrectAnswer('N');
    break;
    case 'novelo':
        setCorrectAnswer('N');
    break;
    case 'panela':
        setCorrectAnswer('N');
    break;
    case 'pena':
        setCorrectAnswer('N');
    break;
    default:
    break;
}
}

const Game1 = () => {
    const navigation = useNavigate();
    const [challenges, setChallenges] = useState(['barbatanas', 'caderno', 'camelo', 'caminho', 'caneta', 'canoa', 'cogumelo', 'macaco', 'mala', 'médico', 'microfone', 'moeda', 'nariz', 'nove', 'novelo', 'panela', 'pena']);
    const [challengeFlag, setChallengeFlag] = useState(challenges[Math.floor(Math.random() * challenges.length)]);  //initialize the challengeFlag randomly on first render
    const [fillWord, setFillWord] = useState(false);  //flag to complete the word displayed on the screen
    const [nextChallenge, setNextChallenge] = useState(false);  //flag to change challenge
    const [finishedGame, setFinishedGame] = useState(false);  //flag to the end of the game's condition
    const [correctAnswer, setCorrectAnswer] = useState(''); //flag to dictate which button gives the correct feedback
    const finishAudio = useMemo(() => new Audio(finishAud),[]);
    finishAudio.volume = 0.5;

    /* Updates correct answer everytime challengeFlag changes*/
    useEffect(() => {
      chooseCorrectAnswer(challengeFlag, setCorrectAnswer);
    },[challengeFlag]);

    /* When changing challenge phase, pop the challengeFlag from the challenges's array */
    useLayoutEffect(() =>{
      if(nextChallenge === true)  setChallenges(current => current.filter(element => {return element !== challengeFlag;}));
    },[nextChallenge, challengeFlag]);

    useEffect(()=>{
      //End of the game's condition
      if(challenges.length === 0 && !finishedGame) {
        finishAudio.play();
        setFinishedGame(true);
        setFillWord(true);
        setNextChallenge(false);
        setChallengeFlag('parabéns');
        //Returns the user to the homepage
        setTimeout(()=>{
          navigation(
            "/",
            {
                replace: true
            }
          );
        }, 4500);
        return (()=>{clearTimeout()});
      }
    },[challenges,finishedGame, finishAudio]);

    //When changing challenge's phase it picks another challengeFlag randomly
    useEffect(()=>{
      if(nextChallenge && !finishedGame){
        setTimeout(() => { 
          setChallengeFlag(challenges[Math.floor(Math.random() * challenges.length)]);
          setNextChallenge(false);
          }, 1000);
        return () => clearTimeout();
      }
    },[nextChallenge, challengeFlag, challenges, finishedGame]);

    return  (<>
      <Layout title={'"M" ou "N"'} description={'Completa a palavra'} flag = {'1'}/>
      <Display>
          <HandleImage flag = {challengeFlag}/>
          <LettersButton leftletter={'M'} rightletter={'N'} finish = {finishedGame} correctAnswer = {correctAnswer} setFillWord = {setFillWord}/>
          <MissingWord finish = {finishedGame} correctAnswer = {correctAnswer} flag = {challengeFlag} fillWord = {fillWord} setFillWord = {setFillWord} setNextChallenge= {setNextChallenge}/>
      </Display>
    </>
    )  
}

export default Game1;