import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import React, { useState } from 'react';

//Import images
import banco from '../assets/Game3/banco.png';
import bigode from '../assets/Game3/bigode.png';
import cabide from '../assets/Game3/cabide.png';
import cama from '../assets/Game3/cama.jpg';
import camarao from '../assets/Game3/camarao.png';
import camisa from '../assets/Game3/camisa.jpg';
import caracol from '../assets/Game3/caracol.png';
import colher from '../assets/Game3/colher.png';
import fogao from '../assets/Game3/fogao.png';
import fogueiro from '../assets/Game3/fogueiro.png';
import garrafa from '../assets/Game3/garrafa.png';
import gaveta from '../assets/Game3/gaveta.jpg';
import gola from '../assets/Game3/gola.jpg';
import golfinho from '../assets/Game3/golfinho.png';
import gota from '../assets/Game3/gota.png';
import microfone from '../assets/Game3/microfone.png';
import minhoca from '../assets/Game3/minhoca.png';
import pacote from '../assets/Game3/pacote.png';
import pipocas from '../assets/Game3/pipocas.png';
import regador from '../assets/Game3/regador.png';
import tartaruga from '../assets/Game3/tartaruga.png';
import triangulo from '../assets/Game3/triangulo.jpg';

//Import audios
import right from '../assets/GeralAudios/correct.wav';
import wrong from '../assets/GeralAudios/wrong.mp3';
import finish from "../assets/GeralAudios/finish.mp3";

//Generates an array with random images. It must include at least one image with the correct letter
function imageRandomizer(){
  const indexes = [];
  const randomImages = [];
  const images = [
    //cImages: 0 - 10
    banco,
    cabide,
    cama,
    camarao,
    camisa,
    caracol,
    colher,
    microfone,
    minhoca, 
    pacote,
    pipocas,
    //gImages: 11-21
    bigode,
    fogao,
    fogueiro,
    garrafa,
    gaveta,
    gola,
    golfinho,
    gota,
    regador, 
    tartaruga,
    triangulo 
  ];

  while (randomImages.length < 5) {
    const randomIndex = Math.floor(Math.random() * images.length);
    const randomImage = images[randomIndex];
    if (!randomImages.includes(randomImage)) {
    randomImages.push(randomImage);
    indexes.push(randomIndex);
    }
  }
  let c = 0;
  let g = 0;
  for (let i = 0; i<5; i++){
    if (indexes[i] < 11){
    c = c+1; 
    } else {
      g = g+1;
    }
  }
  if (c === 0){
    imageRandomizer([]);
  }
  if (g === 0){
    imageRandomizer([]);
  }
  return randomImages;
}

const Image = ({rightAnswer, setrightAnswer, setI}) => {
  const navigation = useNavigate();  
  const [randomImages, setRandomImages] = useState(imageRandomizer());
  const [clickedImages, setClickedImages] = useState([]);
  var [rightCount, setRightCount] = useState(0);
  var [gameCount, setGameCount] = useState(0);
  const [game, setGame] = useState(true);
  const cImages = [banco, cabide, cama, camarao, camisa, caracol, colher, microfone, minhoca, pacote, pipocas];
  const gImages = [bigode, fogao,fogueiro, garrafa, gaveta, gola, golfinho, gota, regador, tartaruga, triangulo]; 
  var cInImages = randomImages.filter(img => cImages.includes(img));
  var gInImages = randomImages.filter(img => gImages.includes(img));
  const cCount = cInImages.length;
  const gCount = gInImages.length;
  var numOfAnsws = 0;

  const FeedbackPosition = {
    position1:{
      position: 'absolute',
      left: '10%',
      top: '15%' , 
      maxHeight : '100px', 
    },
    position2:{
      position: 'absolute',
      left: '40%',
      top: '15%' , 
      maxHeight : '100px',
    },
    position3:{
      position: 'absolute',
      left: '70%',
      top: '15%' , 
      maxHeight : '100px',
    },
      position4:{
        position: 'absolute',
        left: '30%',
        top: '60%' , 
        maxHeight : '100px',
    },
    position5:{
        position: 'absolute',
        left: '53%',
        top: '60%' , 
        maxHeight : '100px',
    }, 
  }

  //Setting the number of right answer as the number of right images coming from the image generator
  if (rightAnswer ==='C'){
    numOfAnsws = cCount;
  } else {
    numOfAnsws = gCount;
  }

  useEffect(() => {    
  }, [rightCount])

  //When a correct image is clicked the first time, it is inserted in tge clicked images array. This allows to count just one time one correct images by using the follwung if cases
  const handleClick = (image) => {

    if (rightAnswer === 'C'){  
      if (cImages.includes(image) && !clickedImages.includes(image)) {
        setClickedImages([...clickedImages, image])
        setRightCount(rightCount => rightCount + 1)
      }
    } else{
      if (gImages.includes(image) && !clickedImages.includes(image)) {
        setClickedImages([...clickedImages, image]);
        setRightCount(rightCount+1);
      }
    }
  }

  //Changes answer for the new activity
  const setAnswer = () => { 
    if (rightAnswer === 'C'){
      setrightAnswer('G');
      setI(1); //Flag for the audio with c
    } else {
      setrightAnswer('C');
    }
  }

  function changeImages (){
    setRandomImages(imageRandomizer())
  }

  //Change activity within the game 3
  const changePage = () => {
      if (gameCount < 3){      
        setAnswer();
        setGameCount(gameCount => gameCount + 1)
        changeImages();
      } else{
        setI(0);
        setTimeout(()=>{
          finishGame();
        },2000);
      }
  }

  //End of the game sound
  function finishGame(){
    setI(0);
    setGame(false);
    setTimeout(()=>{    
      changeImages();
      }, 1500);
    changeImages();
    const finishAudio = new Audio(finish); 
    setTimeout(() => {
      finishAudio.play();
    }, 500);
    setTimeout(()=>{    
      navigation(
        "/",
        {
            replace: true
        }
      );
      }, 4500);
  };
  
  const imageClicked =(img, flag) =>{
    const audio1 = new Audio(right);
    const audio2 = new Audio(wrong);
    handleClick(img);

    //Checks if the selected images is correct or not and gives a feedback sound
    if (cImages.includes(img) && rightAnswer === 'C') {
      audio1.play();
    } else {
      if (gImages.includes(img) && rightAnswer === 'G'){
        audio1.play();
      } else{
        if (cImages.includes(img) && rightAnswer === 'G'){
          audio2.play(); 
      } else {
        if (gImages.includes(img) && rightAnswer === 'C'){
          audio2.play();
          }
        }

      }
    }
  }

  //When the number of correct images clicked is the same as the number of right answer, it goes to the new activity
  if(rightCount === numOfAnsws){
    changePage();
    setRightCount(0);
    setClickedImages([]);
  }

  return (
    ( game ?
    <div>
      <img src={randomImages[0]} style={FeedbackPosition.position1} alt="" onClick={()=>{imageClicked(randomImages[0], 1) }} />
      <img src={randomImages[1]} style={FeedbackPosition.position2} alt="" onClick={()=>{imageClicked(randomImages[1], 2)}} />
      <img src={randomImages[2]} style={FeedbackPosition.position3} alt="" onClick={()=>{imageClicked(randomImages[2], 3)}} />
      
      <div>
        <img src={randomImages[3]} style={FeedbackPosition.position4} alt="" onClick={()=>{imageClicked(randomImages[3], 4)}} />
        <img src={randomImages[4]} style={FeedbackPosition.position5} alt="" onClick={()=>{imageClicked(randomImages[4], 5)}} />
      </div>
    </div>
     : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', 'font-size': '60px'}}>PARABÉNS!!!</div>)
  );
}

export default Image;