import React from "react";

//Import components
import SideBar from "../pages/Menus/Sidebar";
import { Title } from "./Styling";
import { Description } from "./Styling";
import { Header } from "./Styling";

const Layout = ({title, description, flag}) => {

    return (
        <div>
            <SideBar flag = {flag}/>
            <Header>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </Header>

        </div>
    )
}

export default Layout;