import { Link } from 'react-router-dom';

//Import styling
import { LeftSection,ButtonBox, MenuButton, GameButtonSB } from "../../components/Styling";
//Import audios
import jogo1Audio from '../../assets/Game1/audio1/jogo1.mp3';
import jogo2Audio from '../../assets/Game2/audio2/jogo2.mp3';
import jogo3Audio from '../../assets/Game3/audio3/jogo3.mp3';
import jogo4Audio from '../../assets/Game4/audio4/jogo4.mp3';
import jogo5Audio from '../../assets/Game5/audio5/jogo5.mp3';
import jogo6Audio from '../../assets/Game6/audio6/jogo6.mp3';

//Set new audios
var jogo1 = new Audio(jogo1Audio);
var jogo2 = new Audio(jogo2Audio);
var jogo3 = new Audio(jogo3Audio);
var jogo4 = new Audio(jogo4Audio);
var jogo5 = new Audio(jogo5Audio);
var jogo6 = new Audio(jogo6Audio);

//Function to play the game's audio and stop other games' audios to avoid audio overlapping
//The audios need to reset otherwise the remaining part of the audio will play and not the whole audio
function handleAudio(game){
  if(game===1){
    jogo1 = new Audio(jogo1Audio); jogo1.play();
      jogo2.pause();
      jogo3.pause();
      jogo4.pause();
      jogo5.pause();
      jogo6.pause();
  }else if(game===2){
      jogo2 = new Audio(jogo2Audio); jogo2.play();
      jogo1.pause();
      jogo3.pause();
      jogo4.pause();
      jogo5.pause();
      jogo6.pause();
  }else if(game===3){
    jogo3 = new Audio(jogo3Audio); jogo3.play();
    jogo1.pause();
    jogo2.pause();
    jogo4.pause();
    jogo5.pause();
    jogo6.pause();
  }else if(game===4){
    jogo4 = new Audio(jogo4Audio); jogo4.play();
    jogo1.pause();
    jogo2.pause();
    jogo3.pause();
    jogo5.pause();
    jogo6.pause();
  }else if(game===5){
    jogo5 = new Audio(jogo5Audio); jogo5.play();
    jogo1.pause();
    jogo2.pause();
    jogo3.pause();
    jogo4.pause();
    jogo6.pause();
  }else if(game===6){
    jogo6 = new Audio(jogo6Audio); jogo6.play();
    jogo1.pause();
    jogo2.pause();
    jogo3.pause();
    jogo4.pause();
    jogo5.pause();
  }else{
    jogo1.pause();
    jogo2.pause();
    jogo3.pause();
    jogo4.pause();
    jogo5.pause();
    jogo6.pause();
    jogo1 = new Audio(jogo1Audio);
    jogo2 = new Audio(jogo2Audio);
    jogo3 = new Audio(jogo3Audio);
    jogo4 = new Audio(jogo4Audio);
    jogo5 = new Audio(jogo5Audio);
    jogo6 = new Audio(jogo6Audio);
  }
};

const SideBar = ({flag}) => {

  let f1 = 'normal', f2 = 'normal', f3 = 'normal', f4 = 'normal', f5 = 'normal', f6 = 'normal';

  /* When active, the button change's its css */
  switch (flag) {
      case '1':
        f1 = 'active';
        break;
      case '2':
        f2 = 'active';
        break;  
      case '3':
        f3 = 'active';
        break;
      case '4':
        f4 = 'active';
        break;
      case '5':
        f5 = 'active';
        break;
      case '6':
        f6 = 'active';
        break;
      default:
        break;
    }
    return (<LeftSection>
                <Link to="/">
                  <MenuButton onClick={() => {handleAudio(0)}}>Menu</MenuButton>
                  </Link>
                <ButtonBox>
                  <Link to="/jogo1">
                    <GameButtonSB $mode = {f1}  onClick={() => {handleAudio(1)}}>Jogo 1</GameButtonSB>
                  </Link>
                  <Link to="/jogo2">
                    <GameButtonSB $mode = {f2} onClick={() => {handleAudio(2)}}>Jogo 2</GameButtonSB>
                  </Link>
                  <Link to="/jogo3">
                    <GameButtonSB $mode = {f3} onClick={() => {handleAudio(3)}}>Jogo 3</GameButtonSB>
                  </Link>
                  <Link to="/jogo4">
                    <GameButtonSB $mode = {f4} onClick={() => {handleAudio(4)}}>Jogo 4</GameButtonSB>
                  </Link>
                  <Link to="/jogo5">
                    <GameButtonSB $mode = {f5} onClick={() => {handleAudio(5)}}>Jogo 5</GameButtonSB>
                  </Link>
                  <Link to="/jogo6">
                    <GameButtonSB $mode = {f6} onClick={() => {handleAudio(6)}}>Jogo 6</GameButtonSB>
                  </Link>
                </ButtonBox>
           </LeftSection>)
}
export default SideBar;