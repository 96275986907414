import { useRef, useState, useEffect } from 'react';

let currentImage = null;

/* Function responsible for the functionality of drag and drop of the images*/
function useDrag(initialPosition, isVisible) {
  const [position, setPosition] = useState(initialPosition);
  const ref = useRef(null);

  useEffect(() => {
    /* Do these when the image is visible */
    if(isVisible){
        const target = ref.current; 

        /* Follow the mouse's coordinates while the mouse is moving*/
        const handleMouseMove = (event) => {
            if (target === currentImage) //only drags an image at a time
            {
                setPosition({
                x: event.clientX,
                y: event.clientY,
                });
            }
        };

        /* When the image is dropped it stops following the mouse*/
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            currentImage = null;
        };
            
        /* Everytime an image is dragged, the image follows the mouse's coordinates while the mouse is moving*/
        const handleMouseDown = (event) => {
            const initialX = event.clientX - position.x;
            const initialY = event.clientY - position.y;
            
            const handleMouseMove = (event) => {
                if(target === currentImage){
                    setPosition({
                        x: event.clientX - initialX,
                        y: event.clientY - initialY,
                    });   
                }
            }
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
            currentImage = target;
        };

        target.addEventListener('mousedown', handleMouseDown);

        return () => {
            target.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    } 
    /* When the image is invisible, reset its coordinates to default */
    else{
        const target = ref.current;

        /* When the image is dropped it stops following the mouse*/
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            currentImage = null;
            };
        
        /* Follow the mouse's coordinates while the mouse is moving*/
        const handleMouseMove = (event) => {
            if (target === currentImage) {
                setPosition({
                x: event.clientX,
                y: event.clientY,
                });
            }
        };
        /* Reset coordinates to default*/
        if (target === currentImage) {
            setPosition(initialPosition);
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            currentImage = null;
          }
        }
    }, [position, isVisible, initialPosition]);
  return [position, ref];
}
export default useDrag;