import React, { useEffect, useState } from 'react';
import { WordMissing } from '../components/Styling';

const MissingWord = (props) => {
    // Get the word passed in as a prop and convert it to uppercase
    let word = props.flag?.toUpperCase();
    
    // Replace the correct answer in the word with "_"
    word = word?.replace(props.correctAnswer,'_');
    
    // State to keep track of the displayed word
    const [displayedWord, setDisplayedWord] = useState(word);
    
    // Use effect to set the displayed word when the word prop changes
    useEffect(()=>{
      setDisplayedWord(word);
    },[word]);
    
    // Use effect to set the displayed word to "PARABÉNS" if the finish prop is true
    useEffect(()=>{
      if(props.finish)
        setDisplayedWord('PARABÉNS');
    },[props]);

    // Use effect to replace the "_" in the word with the correct answer when the fillWord prop is true
    useEffect(()=>{
      if(props.fillWord === true){
        setDisplayedWord(word?.replace('_', props.correctAnswer));
        props.setFillWord(false);
        props.setNextChallenge(true);
      }
    },[word,props]);

    return (
      <div>
        {/* Render the styled word display component */}
        <WordMissing>{displayedWord}</WordMissing>
      </div>
    );
  }
export default MissingWord;
