import React, {useState, useEffect} from 'react'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

//Import components
import { PopUpWord } from '../components/Styling';

//Import images
import aldeia from "../assets/Game6/aldeia.png";
import alface from "../assets/Game6/alface.jpg";
import alfinete from "../assets/Game6/alfinete.png";
import alga from "../assets/Game6/alga.jpg";
import alguidar from "../assets/Game6/alguidar.png";
import almofada from "../assets/Game6/almofada.jpg";
import anel from "../assets/Game6/anel.jpg";
import balde from "../assets/Game6/balde.png";
import caracol from "../assets/Game6/caracol.png";
import colmeia from "../assets/Game6/colmeia.png";
import funil from "../assets/Game6/funil.png";

//Import audios
import correctAud from '../assets/GeralAudios/correct.wav';
import wrongAud from '../assets/GeralAudios/wrong.mp3';
import aldeiaAudio from '../assets/Game6/audio6/aldeia.mp3';
import alfaceAudio from '../assets/Game6/audio6/alface.mp3';
import alfineteAudio from '../assets/Game6/audio6/alfinete.mp3';
import algaAudio from '../assets/Game6/audio6/alga.mp3';
import alguidarAudio from '../assets/Game6/audio6/alguidar.mp3';
import almofadaAudio from '../assets/Game6/audio6/almofada.mp3';
import anelAudio from '../assets/Game6/audio6/anel.mp3';
import baldeAudio from '../assets/Game6/audio6/balde.mp3';
import caracolAudio from '../assets/Game6/audio6/caracol.mp3';
import colmeiaAudio from '../assets/Game6/audio6/colmeia.mp3';
import funilAudio from '../assets/Game6/audio6/funil.mp3';

const HandleImage = ({flag}) =>{
  let audio = new Audio();
  switch (flag) {
    case "alface":
      audio = new Audio(alfaceAudio);
      return(
      <div>
      <img src={alface} alt = "alface" style={{position: 'absolute', maxHeight: '300px' ,left: '34%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "alguidar":
      audio = new Audio(alguidarAudio);
      return(
      <div>
      <img src={alguidar} alt= "alguidar" style={{position: 'absolute', maxHeight: '300px', left: '32%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "alga":
      audio = new Audio(algaAudio);
      return(
      <div>
      <img src={alga} alt= "alga" style={{position: 'absolute', maxHeight: '300px', left: '40%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "anel":
      audio = new Audio(anelAudio);
      return(
      <div>
      <img src={anel} alt= "anel" style={{position: 'absolute', maxHeight: '300px', left: '36%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "almofada":
      audio = new Audio(almofadaAudio);
      return(
      <div>
      <img src={almofada} alt= "almofada" style={{position: 'absolute', maxHeight: '300px', left: '28%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "alfinete":
      audio = new Audio(alfineteAudio);
      return(
      <div>
      <img src={alfinete} alt= "alfinete" style={{position: 'absolute', maxHeight: '300px', left: '43%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "balde":
      audio = new Audio(baldeAudio);
      return(
      <div>
      <img src={balde} alt= "balde" style={{position: 'absolute', maxHeight: '300px', left: '37%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "colmeia":
      audio = new Audio(colmeiaAudio);
      return(
      <div>
      <img src={colmeia} alt= "colmeia" style={{position: 'absolute', maxHeight: '300px', left: '38%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "funil":
      audio = new Audio(funilAudio);
      return(
      <div>
      <img src={funil} alt= "funil" style={{position: 'absolute', maxHeight: '300px', left: '37%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "caracol":
      audio = new Audio(caracolAudio);
      return(
      <div>
      <img src={caracol} alt= "caracol" style={{position: 'absolute', maxHeight: '300px', left: '31%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "aldeia":
    default:
      audio = new Audio(aldeiaAudio);
      return(
      <div>
      <img src={aldeia} alt= "aldeia" style={{position: 'absolute',   top: '13%', maxHeight: '300px', left: '33%'}} onClick = {() =>{audio.play()}}/>
      </div>)
  }
}

//Button's positions
const styles = {
  position1:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '10%',
    zIndex: 100,
  },
  position2:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '24%',
    zIndex: 100,
  },
  position3:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '38%',
    zIndex: 100,
  },
    position4:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '52%',
    zIndex: 100,
  },
  position5:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '66%',
    zIndex: 100,
  },
  position6:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '80%',
    zIndex: 100,
  },
  position7:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '10%',
    left: '17%',
    zIndex: 100,
  },
  position8:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '10%' ,
    left: '31%',
    zIndex: 100,
  },
  position9:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '10%' ,
    left: '45%',
    zIndex: 100,
  },
  position10:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '10%' ,
    left: '59%',
    zIndex: 100,
  },
  position11:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '10%' ,
    left: '73%',
    zIndex: 100,
  },
};

const Popup6 = (props) => {

  const [correctAnswer, setCorrectAnswer] = useState(0);  //Indicates which button has the right answer
  const [open, setOpen] = useState(false);  //State of the modal window
  //Flags for button activation
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);
  const [active9, setActive9] = useState(false);
  const [active10, setActive10] = useState(false);
  const [active11, setActive11] = useState(false);

  const correctAudio = new Audio(correctAud);
  const wrongAudio = new Audio(wrongAud);

  const modalWindow = {
  position: 'absolute',
  top: '20%',
  left: '20%',
  width: props.width - 68 + 'px',
  height: props.height - 68 + 'px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
};

  /* Opens modal window when props.openFlag is set to true*/
  useEffect(() => {
    setOpen(props.openFlag);
  },[props.openFlag]);

  /* Defines the button with the correct answer */
  useEffect(() => {
    switch (props.modalFlag) {
      case 'alface':
        setCorrectAnswer(1);
      break;
      case 'alguidar':
        setCorrectAnswer(2);
      break;
      case 'alga':
        setCorrectAnswer(3);
      break;
      case 'anel':
        setCorrectAnswer(4);
      break;
      case 'almofada':
        setCorrectAnswer(5);
      break;
      case 'alfinete':
        setCorrectAnswer(6);
      break;
      case 'balde':
        setCorrectAnswer(7);
      break;
      case 'colmeia':
        setCorrectAnswer(8);
      break; 
      case 'funil':
        setCorrectAnswer(9);
      break; 
      case 'caracol':
        setCorrectAnswer(10);
      break;
      case 'aldeia':
        setCorrectAnswer(11);
      break;    
      default:
      break;
    }
  }, [props.modalFlag]);

  let f1 = 'default', f2 = 'default', f3 = 'default', f4 = 'default';
  let f5 = 'default', f6 = 'default', f7 = 'default', f8 = 'default';
  let f9 = 'default', f10 = 'default', f11 = 'default';
  /* Depending on the correct answer, the correct button will change to green onClick, whereas other buttons will change to red in order to give a credible feedback */
  switch (correctAnswer) {
    case 1:
      f1 = 'correct';
    break;
    case 2:
      f2 = 'correct';
    break; 
    case 3:
      f3 = 'correct';
    break;
    case 4:
      f4 = 'correct';
    break;
    case 5:
      f5 = 'correct';
    break;
    case 6:
      f6 = 'correct';
    break;
    case 7:
      f7 = 'correct';
    break;
    case 8:
      f8 = 'correct';
    break;
    case 9:
      f9 = 'correct';
    break;
    case 10:
      f10 = 'correct';
    break;
    case 11:
      f11 = 'correct';
    break;
    default:
    break;
  }

  const deactivatesAll = () =>{
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(false);
    setActive8(false);
    setActive9(false);
    setActive10(false);
    setActive11(false);
    return;
  };

  const handleCorrectAnswer = () =>{ 
    correctAudio.play();
    setTimeout(() => {
      deactivatesAll();
      setOpen(false);
      props.setOpenFlag(false);
      props.setPhaseNum((prevNum)=> prevNum + 1);
  }, 1000);
    return (()=>{clearTimeout()});
  };

  return (
    <div>
    <Modal open={open} onClose={() =>{props.setOpenFlag(false); setOpen(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={modalWindow}>
        <HandleImage flag = {props.modalFlag}/>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <PopUpWord style={styles.position1} $mode={f1} active = {active1} onClick= {()=>{setActive1(true); if(f1 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>alface</PopUpWord>
          <PopUpWord style={styles.position2} $mode={f2} active = {active2} onClick= {()=>{setActive2(true); if(f2 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>alguidar</PopUpWord>
          <PopUpWord style={styles.position3} $mode={f3} active = {active3} onClick= {()=>{setActive3(true); if(f3 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>alga</PopUpWord>
          <PopUpWord style={styles.position4} $mode={f4} active = {active4} onClick= {()=>{setActive4(true); if(f4 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>anel</PopUpWord>
          <PopUpWord style={styles.position5} $mode={f5} active = {active5} onClick= {()=>{setActive5(true); if(f5 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>almofada</PopUpWord>
          <PopUpWord style={styles.position6} $mode={f6} active = {active6} onClick= {()=>{setActive6(true); if(f6 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>alfinete</PopUpWord>
          <PopUpWord style={styles.position7} $mode={f7} active = {active7} onClick= {()=>{setActive7(true); if(f7 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>balde</PopUpWord>
          <PopUpWord style={styles.position8} $mode={f8} active = {active8} onClick= {()=>{setActive8(true); if(f8 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>colmeia</PopUpWord>
          <PopUpWord style={styles.position9} $mode={f9} active = {active9} onClick= {()=>{setActive9(true); if(f9 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>funil</PopUpWord>
          <PopUpWord style={styles.position10} $mode={f10} active = {active10} onClick= {()=>{setActive10(true); if(f10 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>caracol</PopUpWord>
          <PopUpWord style={styles.position11} $mode={f11} active = {active11} onClick= {()=>{setActive11(true); if(f11 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>aldeia</PopUpWord>
        </Typography>
      </Box>
    </Modal>
    </div>
  ); 
}
export default Popup6; 