import React, {useRef, useLayoutEffect, useState} from "react";
import useEventListener from '@use-it/event-listener';

//Import styling
import { Display } from "../components/Styling";

//Import components
import Layout from "../components/Layout";
import Images4 from "../gamedev/DevGame4";

//Import styling
import { Cestas } from "../components/Styling";


const Game4 = () => {
    const canvas = useRef(null);    
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [isVisible, setIsVisible] = useState(true);
    
    /* Gets the proportions of the page on the user's display on first render */
    useLayoutEffect(() => {
        setWidth(canvas.current.offsetWidth);
        setHeight(canvas.current.offsetHeight);
      }, []);

    /* Updates the proportions of the page on the user's display everytime the page is resized */
    function limitHandler(){
        setWidth(canvas.current.offsetWidth);
        setHeight(canvas.current.offsetHeight);
    }
    useEventListener('resize', limitHandler);

    return  (<>
        <Layout title={'"F" ou "V"'} description={'Arrasta a imagem para o cesto correto'} flag = {'4'}/>
        <Display ref = {canvas}>
        {(width!== 0 && height!== 0)  && 
            <div>
                <Images4 refnode = {canvas} width= {width} height = {height} setIsVisible = {setIsVisible}/>
                <Cestas type = "F" style = {{left:"10%",top:"60%", height:height*0.35, width: width*0.175, maxHeight:height, maxWidth: width, display: isVisible ? 'block': 'none'}}/>
                <Cestas type = "V" style = {{left:"72.2%",top:"60%", height:height*0.35, width: width*0.175, maxHeight:height, maxWidth: width, display: isVisible ? 'block': 'none'}}/>
            </div>}
        </Display>
    </>
    );
}
export default Game4;