import { Link } from 'react-router-dom';

//Import styling
import { GameButton } from '../../components/Styling';

//Import audios
import jogo1Audio from '../../assets/Game1/audio1/jogo1.mp3';
import jogo2Audio from '../../assets/Game2/audio2/jogo2.mp3';
import jogo3Audio from '../../assets/Game3/audio3/jogo3.mp3';
import jogo4Audio from '../../assets/Game4/audio4/jogo4.mp3';
import jogo5Audio from '../../assets/Game5/audio5/jogo5.mp3';
import jogo6Audio from '../../assets/Game6/audio6/jogo6.mp3';

//Import components
import handleAudio from '../../components/handleAudio';

//Game buttons position in the screen display
const styles = {
    position1:{
        position: 'absolute',
        width: '10%',
        height: '8%',
        bottom: '50%',
        left: '23%',
        zIndex: 100,
    },
    position2:{
        position: 'absolute',
        width: '10%',
        height: '8%',
        bottom: '50%',
        left: '43%',
        zIndex: 100,
    },
    position3:{
        position: 'absolute',
        width: '10%',
        height: '8%',
        bottom: '50%',
        left: '63%',
        zIndex: 100,
    },
    position4:{
        position: 'absolute',
        width: '10%',
        height: '8%',
        bottom: '30%',
        left: '23%',
        zIndex: 100,
    },
    position5:{
        position: 'absolute',
        width: '10%',
        height: '8%',
        bottom: '30%',
        left: '43%',
        zIndex: 100,
    },
    position6:{
        position: 'absolute',
        width: '10%',
        height: '8%',
        bottom: '30%',
        left: '63%',
        zIndex: 100,
    },

};

const HomePageButton = () => {
    return(
        <div>
            <Link to="/jogo1">
                <GameButton style={styles.position1} onClick = {() => {handleAudio(jogo1Audio)}}>Jogo 1</GameButton>
            </Link>
            <Link to="/jogo2">
                <GameButton style={styles.position2} onClick = {() => {handleAudio(jogo2Audio)}}>Jogo 2</GameButton>
            </Link>
            <Link to="/jogo3">
                <GameButton style={styles.position3} onClick = {() => {handleAudio(jogo3Audio)}}>Jogo 3</GameButton>
            </Link>
            <Link to="/jogo4">
                <GameButton style={styles.position4} onClick = {() => {handleAudio(jogo4Audio)}}>Jogo 4</GameButton>
            </Link>
            <Link to="/jogo5">
                <GameButton style={styles.position5} onClick = {() => {handleAudio(jogo5Audio)}}>Jogo 5</GameButton>
            </Link>
            <Link to="/jogo6">
                <GameButton style={styles.position6} onClick = {() => {handleAudio(jogo6Audio)}}>Jogo 6</GameButton>
            </Link>
        </div>
    )
}
export default HomePageButton;