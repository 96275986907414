import React, {useState, useEffect, useMemo, useRef} from 'react';
import MovingImage from './MovingImages';
import { useNavigate } from "react-router-dom";

//Import audios
import finishAud from '../assets/GeralAudios/finish.mp3';
import smile from '../assets/GeralImagens/sol.png';

//Import images
import afia from '../assets/Game4/afia.png';
import alface from '../assets/Game4/alface.png';
import almofada from '../assets/Game4/almofada.png';
import avental from '../assets/Game4/avental.png';
import avioneta from '../assets/Game4/avioneta.png';
import cafeteira from '../assets/Game4/cafeteira.png';
import chave from '../assets/Game4/chave.png';
import chavena from '../assets/Game4/chavena.png';
import chuveiro from '../assets/Game4/chuveiro.png';
import escova from '../assets/Game4/escova.png';
import formiga from '../assets/Game4/formiga2.png';
import funil from '../assets/Game4/funil.png';
import garfo from '../assets/Game4/garfo2.png';
import gravata from '../assets/Game4/gravata2.png';
import parafuso from '../assets/Game4/parafuso.png';
import sofa from '../assets/Game4/sofa.png';
import vaca from '../assets/Game4/vaca.png';
import vela from '../assets/Game4/vela.png';
import ventoinha from '../assets/Game4/ventoinha.png';

function imageRandomizer(objects) {
  const randomImages = [];
  while (randomImages.length < 5) {
    const randomIndex = Math.floor(Math.random() * objects.length);
    const randomImage = objects[randomIndex]; 
    if (!randomImages.includes(randomImage))
      randomImages.push(randomImage);
  }
  return randomImages;
};

function finishGame(setNextPhase){
  const finishAudio = new Audio(finishAud);
  setNextPhase(false);
  finishAudio.play();
}

const Images4 = (props) => {
  const navigation = useNavigate();
  const [count, setCount] = useState(0);
  const [nextPhase, setNextPhase] = useState(false);
  const [phaseNumber, setPhaseNumber] = useState(0);

  const objects = useMemo(() => [
    afia,
    alface,
    almofada,
    avental,
    avioneta,
    cafeteira,
    chave,
    chavena,
    chuveiro,
    escova,
    formiga,
    funil,
    garfo,
    gravata,
    parafuso,
    sofa,
    vaca,
    vela,
    ventoinha
  ],[]);

  const randomImages = imageRandomizer(objects);  
  const randomImagesRef = useRef(randomImages);
  
  const [images, setImages] = useState([
      { src: randomImages[0], coords: { x: props.width * 0.15, y: props.height * 0.12 } },
      { src: randomImages[1], coords: { x: props.width * 0.45, y: props.height * 0.12 } },
      { src: randomImages[2], coords: { x: props.width * 0.7, y: props.height * 0.12 } },
      { src: randomImages[3], coords: { x: props.width * 0.3, y: props.height * 0.42 } },
      { src: randomImages[4], coords: { x: props.width * 0.55, y: props.height * 0.42} }
    ]);

  useEffect(() => {
    setImages([
      { src: randomImagesRef.current[0], coords: { x: props.width * 0.15, y: props.height * 0.12 } },
      { src: randomImagesRef.current[1], coords: { x: props.width * 0.45, y: props.height * 0.12 } },
      { src: randomImagesRef.current[2], coords: { x: props.width * 0.7, y: props.height * 0.12 } },
      { src: randomImagesRef.current[3], coords: { x: props.width * 0.3, y: props.height * 0.42 } },
      { src: randomImagesRef.current[4], coords: { x: props.width * 0.55, y: props.height * 0.42 } }
    ]);
  }, [props.width, props.height]);
  

  useEffect(() => {
    if(nextPhase){
      randomImagesRef.current = imageRandomizer(objects);
      setImages([
        { src: randomImagesRef.current[0], coords: { x: props.width * 0.15, y: props.height * 0.12 } },
        { src: randomImagesRef.current[1], coords: { x: props.width * 0.45, y: props.height * 0.12 } },
        { src: randomImagesRef.current[2], coords: { x: props.width * 0.7, y: props.height * 0.12 } },
        { src: randomImagesRef.current[3], coords: { x: props.width * 0.3, y: props.height * 0.42 } },
        { src: randomImagesRef.current[4], coords: { x: props.width * 0.55, y: props.height * 0.42 } }
      ]);
      setNextPhase(false);
    }
    if(phaseNumber === 5){
      props.setIsVisible(false);
      finishGame(setNextPhase);
      setTimeout(()=>{
        navigation(
          "/",
          {
              replace: true
          }
      );
      }, 4500);
      return (()=>{clearTimeout()});
    } 
  }, [phaseNumber, nextPhase, props, randomImagesRef, objects]);

  useEffect(() => {
    if(count === 5){
      setTimeout(()=>{
        setNextPhase(true);
        setCount(0);
        setPhaseNumber((prevCount) => prevCount + 1);
      },500);
    }  
  }, [count, images]);

  return (
    <>
      {phaseNumber !== 5 && <div>
       <MovingImage width = {props.width} height = {props.height} images = {images} index = {0} setCount = {setCount} nextPhase = {nextPhase}/>
       <MovingImage width = {props.width} height = {props.height} images = {images} index = {1} setCount = {setCount} nextPhase = {nextPhase}/>
       <MovingImage width = {props.width} height = {props.height} images = {images} index = {2} setCount = {setCount} nextPhase = {nextPhase}/>
       <MovingImage width = {props.width} height = {props.height} images = {images} index = {3} setCount = {setCount} nextPhase = {nextPhase}/>
       <MovingImage width = {props.width} height = {props.height} images = {images} index = {4} setCount = {setCount} nextPhase = {nextPhase}/>
       </div>}
       {phaseNumber === 5 && <img src={smile} alt="smile" style={{
                position: 'absolute',
                maxHeight: props.width*0.3,
                maxWidth: props.width*0.3,
                left: '35%',
                top: '20%',
                }}/>}
    </>
  );
};
export default Images4;

