import React, {useState, useEffect} from 'react'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

//Import styling
import { PopUpWord } from '../components/Styling';

//Import images
import escada from "../assets/Game5/escada.jpg";
import escola from '../assets/Game5/escola.jpg';
import espada from '../assets/Game5/espada.jpg';
import espelho from '../assets/Game5/espelho.jpg';
import esqueleto from '../assets/Game5/esqueleto.jpg';
import esquilo from '../assets/Game5/esquilo.jpg';
import estatua from '../assets/Game5/estatua.jpg';
import estojo from '../assets/Game5/estojo.jpg';

//Import audios
import correctAud from '../assets/GeralAudios/correct.wav';
import wrongAud from '../assets/GeralAudios/wrong.mp3';
import escadaAudio from '../assets/Game5/audio5/escada.mp3';
import escolaAudio from '../assets/Game5/audio5/escola.mp3';
import espadaAudio from '../assets/Game5/audio5/espada.mp3';
import espelhoAudio from '../assets/Game5/audio5/espelho.mp3';
import esqueletoAudio from '../assets/Game5/audio5/esqueleto.mp3';
import esquiloAudio from '../assets/Game5/audio5/esquilo.mp3';
import estatuaAudio from '../assets/Game5/audio5/estatua.mp3';
import estojoAudio from '../assets/Game5/audio5/estojo.mp3';

const HandleImage = ({flag}) =>{
  let audio = new Audio();
  switch (flag) {
    case "escola":
      audio = new Audio(escolaAudio);
      return(
      <div>
      <img src={escola} alt = "escola" style={{position: 'absolute', maxHeight: '300px' ,left: '36%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "espada":
      audio = new Audio(espadaAudio);
      return(
      <div>
      <img src={espada} alt= "espada" style={{position: 'absolute', maxHeight: '300px', left: '36%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "espelho":
      audio = new Audio(espelhoAudio);
      return(
      <div>
      <img src={espelho} alt="espelho" style={{position: 'absolute', maxHeight: '300px', left:'36%'}} onClick = {() =>{audio.play()}}/>
      </div>)
    case "esqueleto":
      audio = new Audio(esqueletoAudio);
      return(
      <div>
      <img src={esqueleto} alt="esqueleto" style={{position: 'absolute', maxHeight: '300px', left:'40%'}} onClick = {() =>{audio.play()}}/>
      </div>
    )
    case "esquilo":
      audio = new Audio(esquiloAudio);
      return(
      <div>
      <img src={esquilo} alt="esquilo" style={{position: 'absolute', maxHeight: '300px', left:'38%'}} onClick = {() =>{audio.play()}}/>
      </div>
    )
    case "estatua":
      audio = new Audio(estatuaAudio);
      return(
      <div>
      <img src={estatua} alt="estatua" style={{position: 'absolute', maxHeight: '300px', left:'39%'}} onClick = {() =>{audio.play()}}/>
      </div>
    )
    case "estojo":
      audio = new Audio(estojoAudio);
      return(
      <div>
      <img src={estojo} alt="estojo" style={{position: 'absolute', maxHeight: '250px', left:'26%', top:'15%'}} onClick = {() =>{audio.play()}}/>
      </div>
    )
    case "escada":
    default:
      audio = new Audio(escadaAudio);
      return(
      <div>
      <img src={escada} alt = "escada" style= {{ position: 'absolute', maxHeight: '300px', left: '41%' }} onClick = {() =>{audio.play()}}/>
      </div>)
  }
}

//Button's positions
const styles = {
  position1:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '18%',
    zIndex: 100,
  },
  position2:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '36%',
    zIndex: 100,
  },
  position3:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '54%',
    zIndex: 100,
  },
    position4:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '20%',
    left: '72%',
    zIndex: 100,
  },
  position5:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '10%',
    left: '18%',
    zIndex: 100,
  },
  position6:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '10%',
    left: '36%',
    zIndex: 100,
  },
  position7:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '10%',
    left: '54%',
    zIndex: 100,
  },
  position8:{
    position: 'absolute',
    width: 120,
    height: 40,
    bottom: '10%' ,
    left: '72%',
    zIndex: 100,
  },
};

const Popup5 = (props) => {
  const [correctAnswer, setCorrectAnswer] = useState(0);  //Indicates which button has the right answer
  const [open, setOpen] = useState(false);  //State of the modal window
  //Flags for button activation
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const [active6, setActive6] = useState(false);
  const [active7, setActive7] = useState(false);
  const [active8, setActive8] = useState(false);

  const correctAudio = new Audio(correctAud);
  const wrongAudio = new Audio(wrongAud);

  const modalWindow = {
  position: 'absolute',
  top: '20%',
  left: '20%',
  width: props.width - 68 + 'px',
  height: props.height - 68 + 'px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
  };

  /* Opens modal window when props.openFlag is set to true*/
  useEffect(() => {
    setOpen(props.openFlag);
  },[props.openFlag]);

  /* Defines the button with the correct answer */
  useEffect(() => {
    switch (props.modalFlag) {
      case 'escada':
        setCorrectAnswer(1);
      break;
      case 'escola':
        setCorrectAnswer(2);
      break;
      case 'espada':
        setCorrectAnswer(3);
      break;
      case 'espelho':
        setCorrectAnswer(4);
      break;
      case 'esqueleto':
        setCorrectAnswer(5);
      break;
      case 'esquilo':
        setCorrectAnswer(6);
      break;
      case 'estatua':
        setCorrectAnswer(7);
      break;
      case 'estojo':
        setCorrectAnswer(8);
      break; 
      default:
      break;
    }
  }, [props.modalFlag]);

  let f1 = 'default', f2 = 'default', f3 = 'default', f4 = 'default';
  let f5 = 'default', f6 = 'default', f7= 'default', f8= 'default';
  /* Depending on the correct answer, the correct button will change to green onClick, whereas other buttons will change to red in order to give a credible feedback */
  switch (correctAnswer) {
    case 1:
      f1 = 'correct';
    break;
    case 2:
      f2 = 'correct';
    break; 
    case 3:
      f3 = 'correct';
    break;
    case 4:
      f4 = 'correct';
    break;
    case 5:
      f5 = 'correct';
    break;
    case 6:
      f6 = 'correct';
    break;
    case 7:
      f7 = 'correct';
    break;
    case 8:
      f8 = 'correct';
    break;
    default:
    break;
  }

  const deactivatesAll = () =>{
    setActive1(false);
    setActive2(false);
    setActive3(false);
    setActive4(false);
    setActive5(false);
    setActive6(false);
    setActive7(false);
    setActive8(false);
    return;
  };

  const handleCorrectAnswer = () =>{ 
    correctAudio.play();
    setTimeout(() => {
      deactivatesAll();
      setOpen(false);
      props.setOpenFlag(false);
      props.setPhaseNum((prevNum)=> prevNum + 1);
  }, 1000);
    return (()=>{clearTimeout()});
  };

  return (
    <div>
    <Modal open={open} onClose={() =>{props.setOpenFlag(false); setOpen(false)}} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={modalWindow}>
        <HandleImage flag = {props.modalFlag}/>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <PopUpWord style={styles.position1} $mode={f1} active = {active1} onClick= {()=>{setActive1(true); if(f1 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>escada</PopUpWord>
          <PopUpWord style={styles.position2} $mode={f2} active = {active2} onClick= {()=>{setActive2(true); if(f2 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>escola</PopUpWord>
          <PopUpWord style={styles.position3} $mode={f3} active = {active3} onClick= {()=>{setActive3(true); if(f3 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>espada</PopUpWord>
          <PopUpWord style={styles.position4} $mode={f4} active = {active4} onClick= {()=>{setActive4(true); if(f4 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>espelho</PopUpWord>
          <PopUpWord style={styles.position5} $mode={f5} active = {active5} onClick= {()=>{setActive5(true); if(f5 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>esqueleto</PopUpWord>
          <PopUpWord style={styles.position6} $mode={f6} active = {active6} onClick= {()=>{setActive6(true); if(f6 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>esquilo</PopUpWord>
          <PopUpWord style={styles.position7} $mode={f7} active = {active7} onClick= {()=>{setActive7(true); if(f7 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>estátua</PopUpWord>
          <PopUpWord style={styles.position8} $mode={f8} active = {active8} onClick= {()=>{setActive8(true); if(f8 === 'correct')handleCorrectAnswer(); else{wrongAudio.play();}}}>estojo</PopUpWord>
        </Typography>
      </Box>
    </Modal>
    </div>
  ); 
}
export default Popup5; 